import React, { useState, useMemo } from 'react';
import { Bar, ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,ReferenceLine, Label  } from 'recharts';
import data7days from "../../jsons/data/election_support_running_avg_7_day.json"; 
import data14days from "../../jsons/data/election_support_running_avg_14_day.json";
import data1month from "../../jsons/data/election_support_running_avg_30_day.json";
import data538_7days from "../../jsons/data/538_election_support_running_avg_7_day.json";
import data538_14days from "../../jsons/data/538_election_support_running_avg_14_day.json";
import data538_1month from "../../jsons/data/538_election_support_running_avg_30_day.json";
import postrat_avg_7days from "../../jsons/data/postrat_avg_7_days.json";
import postrat_avg_14days from "../../jsons/data/postrat_avg_14_days.json";
import postrat_avg_30days from "../../jsons/data/postrat_avg_30_days.json";
import postrat_biden from "../../jsons/data/poststrat_frontend_biden_avg_over_30_days.json";
import postrat_harris from "../../jsons/data/poststrat_frontend_harris_avg_over_30_days.json";
import { FaInfoCircle } from 'react-icons/fa';
import './VoteChart.css';

const CustomLegend = ({ payload, show538, showPostratified }) => {
  return (
    <ul className='custom-legend'>
      {payload
        .filter(entry => entry.dataKey !== 'num_polls_in_last_date_harris') 
        .filter(entry => entry.dataKey !== 'num_polls_in_last_date_biden')
        .map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            <svg width="20" height="10">
              {show538 && entry.dataKey.includes('538') && (
                <line x1="0" y1="5" x2="20" y2="5" stroke={entry.color} strokeWidth="4" strokeDasharray="5 5" />
              )}
              {!show538 && !showPostratified && (
                <line x1="0" y1="5" x2="20" y2="5" stroke={entry.color} strokeWidth="2" strokeDasharray="3 3"/>
              )}
              {showPostratified && entry.dataKey.includes('postratified') && (
                <line x1="0" y1="5" x2="20" y2="5" stroke={entry.color} strokeWidth="6" />
              )}
              {!entry.dataKey.includes('538') && !entry.dataKey.includes('postratified') && (
                <line x1="0" y1="5" x2="20" y2="5" stroke={entry.color} strokeWidth="2" strokeDasharray="3 3"/>
              )}
            </svg>
            {entry.value}
          </li>
        ))}
    </ul>
  );
};

const VoteChart = () => {
  const [data, setData] = useState({
    '7days': data7days,
    '14days': data14days,
    '1month': data1month,
    '538_7days': data538_7days,
    '538_14days': data538_14days,
    '538_1month': data538_1month,
    'postrat_7days': postrat_avg_7days,
    'postrat_14days': postrat_avg_14days,
    'postrat_1month': postrat_avg_30days,
    'postrat_biden': postrat_biden,
    'postrat_harris': postrat_harris,
  });

  console.log(setData);

  const [filter, setFilter] = useState('1month');
  const [showRescaled, setShowRescaled] = useState(true);
  const [show538, setShow538] = useState(false);
  const [showPostratified, setShowPostratified] = useState(true);
  const [disablePostratified, setDisablePostratified] = useState(false);
  const [showHarris, setShowHarris] = useState(true);

  const processData = (data, filter, showRescaled) => {
    const selectedData = data[filter] || {};
    const filteredData = Object.keys(selectedData)
      .filter(date => showHarris ? date >= '2024-07-04' && date <= '2024-11-06': date >= '2024-02-22' && date<= '2024-07-25') 
      .map((date) => {
        let avg_perc_trump = 0;
        let avg_perc_opponent = 0;
        if (showHarris) {
          avg_perc_trump = showRescaled
            ? parseFloat(selectedData[date].avg_perc_trump_rescaled_against_harris?.toFixed(1))
            : parseFloat(selectedData[date].avg_perc_trump?.toFixed(1));
          avg_perc_opponent = showRescaled
            ? parseFloat(selectedData[date].avg_perc_harris_rescaled?.toFixed(1))
            : parseFloat(selectedData[date].avg_perc_harris?.toFixed(1));
        } else {
          avg_perc_trump = showRescaled
            ? parseFloat(selectedData[date].avg_perc_trump_rescaled_against_biden?.toFixed(1))
            : parseFloat(selectedData[date].avg_perc_trump?.toFixed(1));
          avg_perc_opponent = showRescaled
            ? parseFloat(selectedData[date].avg_perc_biden_rescaled?.toFixed(1))
            : parseFloat(selectedData[date].avg_perc_biden?.toFixed(1));
        }
        return {
          ...selectedData[date],
          avg_perc_trump,
          avg_perc_opponent,
          date,
        };
      })
      .filter(item => item.avg_perc_trump !== null && item.avg_perc_opponent !== null);
    return filteredData;
  };

  const processPostratifiedData = (data, filter, showRescaled) => {
    const selectedData = data[filter] || [];
    console.log('Raw selected data:', selectedData);
  
    const filteredData = selectedData
      .filter(item => {
        const date = item.date;
        // Filter based on the date, depending on whether we are showing Harris or not
        return showHarris ? date >= '2024-06-28' : date >= '2024-02-22';
      })
      .map(item => {
        let avg_perc_trump = 0;
        let avg_perc_opponent = 0;
  
        if (showHarris) {
          avg_perc_trump = showRescaled
            ? parseFloat(item.avg_perc_trump_rescaled?.toFixed(1))
            : null;
          avg_perc_opponent = showRescaled
            ? parseFloat(item.avg_perc_harris_rescaled?.toFixed(1))
            : null;
        } else {
          avg_perc_trump = showRescaled
            ? parseFloat(item.avg_perc_trump_rescaled?.toFixed(1))
            : null;
          avg_perc_opponent = showRescaled
            ? parseFloat(item.avg_perc_biden_rescaled?.toFixed(1))
            : null;
        }
  
        return {
          ...item,
          avg_perc_trump,
          avg_perc_opponent,
        };
      })
      .filter(item => item.avg_perc_trump !== null && item.avg_perc_opponent !== null);
  
    console.log('Processed data:', filteredData);
  
    return filteredData;
  };
  
  
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setDisablePostratified(newFilter === '7days' || newFilter === '14days');
    if (newFilter === '7days' || newFilter === '14days') {
      setShowPostratified(false);
    }
  };

  const handleToggleChange = () => {
    const newShowRescaled = !showRescaled;
    setShowRescaled(newShowRescaled);
    if (!newShowRescaled) {
      setShowPostratified(false);
    }
  };

  const handleToggle538Change = () => {
    setShow538(!show538);
  };

  const handleTogglePostratifiedChange = () => {
    if (!disablePostratified) {
      setShowPostratified(!showPostratified);
      if (!showPostratified) {
        setShowRescaled(true);
      }
    }
  };  

  const handleToggleHarrisChange = () => {
    setShowHarris(!showHarris);
  };

  const getXAxisTicks = useMemo(() => {
    const selectedData = data[filter] || {};
    const ticks = Object.keys(selectedData).filter((date, index, self) => {
      if (index === 0) {
        return true; 
      }
      const prevDate = self[index - 1];
      const currentDate = date;
      const prevMonth = new Date(prevDate).getMonth();
      const currentMonth = new Date(currentDate).getMonth();
      return prevMonth !== currentMonth; 
    });
    return ticks;
  }, [data, filter]);

  const formatTooltipDate = (date, filter) => {
    const currentDate = new Date(date);
    let startDate;
    switch (filter) {
      case '7days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 7);
        break;
      case '14days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 14);
        break;
      case '1month':
        startDate = new Date(currentDate);
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      default:
        startDate = new Date(currentDate);
        break;
    }
    
    const formattedStartDate = `${startDate.toLocaleString('default', { month: 'short' })} ${startDate.getDate()}`;
    const formattedEndDate = `${currentDate.toLocaleString('default', { month: 'short' })} ${currentDate.getDate()}`;
    
    return `From ${formattedStartDate} to ${formattedEndDate}`;
  };

  const formatXAxis = (tickItem) => {
    const dateObj = new Date(tickItem);
    const month = dateObj.toLocaleString('default', { month: 'short' }); 
    const day = dateObj.getDate(); 
    return `${month} ${day}`;
  };

  const filteredData = processData(data, filter, showRescaled, showPostratified);
  const filteredData538 = processData(data, `538_${filter}`, showRescaled, showPostratified);
  const filteredDataBidenPostratified = processPostratifiedData(data, `postrat_biden`, showRescaled, showPostratified);
  const filteredDataHarrisPostratified = processPostratifiedData(data, `postrat_harris`, showRescaled, showPostratified);

  const combinedData = filteredData.map((item) => {
    // console.log("Item Date:", item.date, typeof item.date);
    // filteredDataBidenPostratified.forEach(data => console.log("Postratified Biden Date:", data, typeof data.date));
    // filteredDataHarrisPostratified.forEach(data => console.log("Postratified Harris Date:", data.date, typeof data.date));

    const corresponding538Data = filteredData538.find(fiveThirtyEightItem => fiveThirtyEightItem.date === item.date);
    const correspondingBidenPostratifiedData = filteredDataBidenPostratified.find(postratifiedItem => postratifiedItem.date === item.date);
    const correspondingHarrisPostratifiedData = filteredDataHarrisPostratified.find(postratifiedItem => postratifiedItem.date === item.date);
    if(showHarris){
      return{
        ...item,
      avg_perc_trump_538: corresponding538Data ? corresponding538Data.avg_perc_trump : null,
      avg_perc_harris_538: corresponding538Data ? corresponding538Data.avg_perc_harris : null,
      avg_perc_trump_rescaled_against_harris_538: corresponding538Data ? corresponding538Data.avg_perc_trump_rescaled_against_harris : null,
      avg_perc_harris_rescaled_538: corresponding538Data ? corresponding538Data.avg_perc_harris_rescaled : null,
      avg_perc_trump_postratified: correspondingHarrisPostratifiedData ? correspondingHarrisPostratifiedData.avg_perc_trump_rescaled : null,
      avg_perc_biden_postratified: correspondingHarrisPostratifiedData ? correspondingHarrisPostratifiedData.avg_perc_harris_rescaled : null,
      }
    }
    return {
      ...item,
      avg_perc_trump_538: corresponding538Data ? corresponding538Data.avg_perc_trump : null,
      avg_perc_biden_538: corresponding538Data ? corresponding538Data.avg_perc_biden : null,
      avg_perc_trump_rescaled_against_biden_538: corresponding538Data ? corresponding538Data.avg_perc_trump_rescaled_against_biden : null,
      avg_perc_biden_rescaled_538: corresponding538Data ? corresponding538Data.avg_perc_biden_rescaled : null,
      avg_perc_trump_postratified: correspondingBidenPostratifiedData ? correspondingBidenPostratifiedData.avg_perc_trump_rescaled : null,
      avg_perc_biden_postratified: correspondingBidenPostratifiedData ? correspondingBidenPostratifiedData.avg_perc_biden_rescaled : null
    };
  });


  return (
    <div className='chart-container'>
      <h1 className='chart-header'>Timeline</h1>
      <div className='chart-description-container'>
      {showHarris ? (
        <p className='chart-description'>
          Discover changes in support for Donald Trump and Kamala Harris. Social poll outcomes are biased, so check out also traditional poll outcomes and our de-biased social poll outcomes!
        </p>
      ) : (
        <p className='chart-description'>
          Discover changes in support for Donald Trump and Joe Biden. Social poll outcomes are biased, so check out also traditional poll outcomes and our de-biased social poll outcomes!
        </p>
      )}
        <div className='info-icon-container'>
          <FaInfoCircle className='info-icon' />
          <div className='info-tooltip'>
            The figure shows (running) averages over the selected time period preceding given date.
          </div>
        </div>
      </div>
      <div className='button-container'>
        <div className='button-group'>  
          <button
            className={`button-toggle ${show538 ? 'active' : ''}`}
            onClick={handleToggle538Change}
          >
            Traditional polls(from 538)
          </button>
          <div className='info-icon-container'>
            <FaInfoCircle className='info-icon' />
            <div className='info-tooltip'>Show additional lines based on 538 data.</div>
          </div>
          <button
            className={`button-toggle ${showPostratified ? 'active' : ''} ${disablePostratified ? 'disabled' : ''}`}
            onClick={handleTogglePostratifiedChange}
            disabled={disablePostratified}
          >
            De-biased Social Polls
          </button>
          <div className='info-icon-container'>
            <FaInfoCircle className='info-icon' />
            <div className='info-tooltip'>The results of social polls post-stratified on demographics and partisanship of poll retweeters.</div>
          </div>
        </div>
      </div> 
      <ResponsiveContainer width='100%' height={500}>
        <ComposedChart data={combinedData}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis 
            dataKey='date' 
            tickFormatter={formatXAxis}
            ticks={getXAxisTicks}
            style={{ fontSize: '14px', fontFamily: 'Manrope, sans-serif' }}
          />
          <YAxis 
            label={{  value: show538 ? '% of votes' : '% of votes in X polls', angle: -90, position : 'insideLeft' ,offset: 10,dy: 80, textAnchor: 'middle'}}
            style={{ fontSize: '14px', fontFamily: 'Manrope, sans-serif' }} 
            yAxisId={"left"}
            orientation='left'
            domain={[0, 100]}  
            interval={0}
          />
          <YAxis 
            label={{  value: 'Number of polls per day', angle: -90 , position: 'insideLeft', offset: 45, dy: 80, textAnchor: 'middle' }}
            style={{ fontSize: '14px', fontFamily: 'Manrope, sans-serif' }} 
            yAxisId={"right"}
            orientation='right' 
          />
          <Tooltip 
            contentStyle={{ fontFamily: 'Manrope, sans-serif', fontSize: '14px', fontWeight: '600'}}
            formatter={(value, name) => {
              if (name === 'num_polls_in_last_date_harris' || name === 'num_polls_in_last_date_biden') {
                return [
                  <div key="num_polls_in_last_date">
                    <span style={{ color: '#222222' }}>No of polls:</span>
                    <span style={{ color: '#222222' }}>{value}</span>
                  </div>
                ];
              } else {
                return [`${value.toFixed(2)}%`, name]; 
              }
            }} 
            labelFormatter={date => formatTooltipDate(date, filter)}
          />
          <ReferenceLine
            x="2024-07-21"
            stroke="black"
            strokeDasharray="10 10"
            strokeWidth={4}
            yAxisId="left"
          >
            <Label
              value="Biden resigned"
              position="top"
              offset={-10} 
              fill="black"
              textAnchor="end"
              dx={-60}
              dy={10}
            />
            <Label
              value="Jul 21"
              position="bottom"
              offset={10} 
              fill="black"
            />
          </ReferenceLine>
          <Legend content={<CustomLegend payload={combinedData} show538={show538} showPostratified={showPostratified} />} wrapperStyle={{ 
              fontSize: '18px', 
              fontFamily: 'Manrope, sans-serif',
              fontWeight: 'bold'
            }} />
          <Line 
          yAxisId="left"
            type='monotone' 
            dataKey={
              showHarris 
                ? (showRescaled ? 'avg_perc_trump_rescaled_against_harris' : 'avg_perc_trump')
                : (showRescaled ? 'avg_perc_trump_rescaled_against_biden' : 'avg_perc_trump')
            } 
            stroke='#e04040' 
            name='Trump' 
            strokeWidth={2} 
            strokeDasharray='3 3'
            dot={false} 
          />
          <Line 
          yAxisId="left"
            type='monotone' 
            dataKey={
              showHarris 
                ? (showRescaled ? 'avg_perc_harris_rescaled' : 'avg_perc_harris')
                : (showRescaled ? 'avg_perc_biden_rescaled' : 'avg_perc_biden')
            } 
            stroke='#0000FF' 
            name={
              showHarris ? 'Harris' : 'Biden'
            } 
            strokeWidth={2} 
            strokeDasharray='3 3'
            dot={false} 
          />
          {show538 && (
            <>
              <Line 
              yAxisId="left"
                type='monotone' 
                dataKey={
                  showHarris 
                    ? (showRescaled ? 'avg_perc_trump_rescaled_against_harris_538' : 'avg_perc_trump_538')
                    : (showRescaled ? 'avg_perc_trump_rescaled_against_biden_538' : 'avg_perc_trump_538')
                } 
                stroke='#e04040' 
                name='Trump(538)' 
                strokeWidth={4} 
                dot={false} 
                strokeDasharray='5 5'
              />
              <Line 
              yAxisId="left"
                type='monotone' 
                dataKey={
                  showHarris 
                    ? (showRescaled ? 'avg_perc_harris_rescaled_538' : 'avg_perc_harris_538')
                    : (showRescaled ? 'avg_perc_biden_rescaled_538' : 'avg_perc_biden_538')
                } 
                stroke='#0000FF' 
                name={
                  showHarris ? 'Harris(538)' : 'Biden(538)'
                }  
                strokeWidth={4} 
                dot={false} 
                strokeDasharray='5 5'
              />
            </>
          )}
          {showPostratified && (
            <>
              <Line type='monotone' yAxisId="left" dataKey='avg_perc_trump_postratified' stroke='#e04040' strokeWidth={6}  name='Trump De-biased' dot={false}/>
              <Line type='monotone'  yAxisId="left" dataKey='avg_perc_biden_postratified' stroke='#0000FF' strokeWidth={6}  name={showHarris === true ? 'Harris De-biased' : 'Biden De-biased'} dot={false}/>
            </>
          )}
          <Bar yAxisId="right" dataKey={showHarris ? 'num_polls_in_last_date_harris' : 'num_polls_in_last_date_biden'} fill='rgba(34, 34, 34, 0.25)' barSize={50} />
        </ComposedChart>
      </ResponsiveContainer>
      <div className='checkbox-container'>
      <div className='toggleButton'>
        <label className='switch'>
          <input
            type='checkbox'
            checked={showHarris}
            onChange={handleToggleHarrisChange}
          />
          <span className='slider'></span>
        </label>
        <span className='toggleLabel'>Harris instead of Biden</span>
      </div>
      <div className='toggleButton'>
        <label className='switch'>
        <input
          type='checkbox'
          checked={showRescaled}
          onChange={handleToggleChange}
        />
        <span className='slider'></span>
        </label>
        <span className='toggleLabel'>Show Head-to-Head</span>
        <div className='info-icon-container'>
          <FaInfoCircle className='info-icon' />
            <div className='info-tooltip'>Head-to-head percentages highlight changes in the two-party vote, so the vote percentages for the two leading candidates sum up to 100% (see Methodology)</div>
        </div>
      </div>
      </div>
    <span className='label'>Each point is an average over:</span>
    <div className='tab-container'>
        <div className={`tab ${filter === '7days' ? 'active' : ''}`} onClick={() => handleFilterChange('7days')}>
          1 Week
        </div>
        <div className={`tab ${filter === '14days' ? 'active' : ''}`} onClick={() => handleFilterChange('14days')}>
          2 Weeks
        </div>
        <div className={`tab ${filter === '1month' ? 'active' : ''}`} onClick={() => handleFilterChange('1month')}>
          1 Month
        </div>
      </div>
    </div>
  );
};
export default VoteChart;